import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { AppBrands } from "@common/enums/appBrands.enum";
import { getAppBrand } from "@common/services/getEnvApplicationSettings.service";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { LoginForm } from "@portal-account/components/LoginForm/LoginForm";
import { SignInForm } from "@portal-account/components/SignInForm/SignInForm";
import { SignInGoToGuestPay } from "@portal-account/components/SignInGoToGuestPay/SignInGoToGuestPay";
import { SignInPageTranslations } from "@portal-account/pages/SignInPage/SignInPage.en.i18n";
import {
  SignInOptions,
  Skyline,
  StyledColumn,
  StyledForgotPasswordLink,
  StyledPageContainer,
  StyledTitle,
} from "@portal-account/pages/SignInPage/SignInPage.styled";
import { forgotPasswordPath } from "@portal-account/routes/routePaths";
import { IRONHIDE_SIGN_IN_URL } from "@portal-enroll/constants/urls.constant";
import { googleAnalyticsDataAtom } from "@portal-shared/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking.atoms";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export const SignInPage = () => {
  const appBrand = getAppBrand();
  const { translate, translateJsx } = useTranslations();
  const { portalSkyline } = useBrandImageUrls();
  const setGoogleAnalyticsDataAtom = useSetAtom(googleAnalyticsDataAtom);

  useEffect(() => {
    setGoogleAnalyticsDataAtom(RESET);
  }, [setGoogleAnalyticsDataAtom]);

  const {
    tSignInPageForgotPassword,
    tSignInPageHtmlDescription,
    tSignInPageHtmlTitle,
    tSignInPageLogIn,
  } = translate(SignInPageTranslations);

  const { tSignInPageAreYouAnEntrustCustomer } = translateJsx({
    tSignInPageAreYouAnEntrustCustomer: {
      link: (message: string) => (
        <a href={IRONHIDE_SIGN_IN_URL} rel="noreferrer">
          {message}
        </a>
      ),
    },
  });

  const { announceNotice, clearAnnouncement } = useRhAnnouncement();

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalAccountOktaBackendProxy } = featureFlagClient.getFlags([
    ["portalAccountOktaBackendProxy", false],
  ]);

  useEffect(() => {
    if (appBrand === AppBrands.RHYTHM) {
      announceNotice(<div>{tSignInPageAreYouAnEntrustCustomer}</div>);
    }

    return clearAnnouncement;
  }, [appBrand]);

  return (
    <>
      <Helmet>
        <title>{tSignInPageHtmlTitle}</title>
        <meta name="description" content={tSignInPageHtmlDescription} />
        <meta name="robots" content="index" />
      </Helmet>
      <StyledPageContainer>
        <StyledColumn>
          <SignInOptions>
            <StyledTitle variant="h1">{tSignInPageLogIn}</StyledTitle>

            {portalAccountOktaBackendProxy.value ? (
              <LoginForm />
            ) : (
              <SignInForm />
            )}

            <StyledForgotPasswordLink to={forgotPasswordPath()}>
              {tSignInPageForgotPassword}
            </StyledForgotPasswordLink>
          </SignInOptions>
        </StyledColumn>
        <StyledColumn>
          <SignInGoToGuestPay />
        </StyledColumn>
      </StyledPageContainer>
      <Skyline style={{ backgroundImage: `url(${portalSkyline})` }} />
    </>
  );
};
