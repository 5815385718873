import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { PortalCard } from "@portal-shared/components/PortalCard/PortalCard.styled";
import styled from "styled-components";

export const StyledRewardsContainer = styled.div`
  & > *:first-child {
    flex: 1 0 48%;
  }

  & > *:nth-child(2) {
    flex: 1 0 52%;
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    display: flex;
  }
`;

export const StyledTextContainer = styled.div`
  flex: 1;
  margin-right: ${rhSpacingPx(1)};

  & > :last-child {
    &.MuiTypography-root {
      margin-top: ${rhSpacingPx(1)};
    }
  }
`;

export const StyledIllustrationContainer = styled.div`
  flex-basis: 94;

  & > img {
    width: 130px;
  }
`;

export const StyledUnlockTextContainer = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacingPx(2)};
  }
`;

export const StyledListPoints = styled.ul`
  & > li {
    flex: 1 0 33%;
    margin-bottom: ${rhSpacingPx(1.5)};
    margin-left: ${rhSpacingPx(2)};
    margin-right: ${rhSpacingPx(2)};
  }

  & > li::marker {
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  }

  display: flex;
  flex-wrap: wrap;
  list-style: disc;
`;

export const CustomerRewardsCard = styled(PortalCard)`
  margin-bottom: ${rhSpacingPx(2.5)};
`;

export const RewardsCard = styled(PortalCard)`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(3)};
  height: 100%;
  justify-content: space-between;
`;

export const ActionsContainer = styled.div`
  padding: ${rhSpacingPx(2.5)};
  padding-left: 0;
`;

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(2)};
`;

export const BalanceText = styled(RhTypography)`
  &.MuiTypography-root {
    margin-right: ${rhSpacingPx(0.75)};
  }
`;
