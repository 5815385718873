import { DefaultTheme } from "styled-components";

export const RhythmTheme: DefaultTheme = {
  colors: {
    black: "#000000",
    disabled: {
      background: "#EDEBF0",
      border: "#EDEBF0",
      hover: "#777380",
      text: "#777380",
    },
    error: {
      dark: "#C24842",
      light: "#FFD6D8",
      main: "#B30309",
    },
    grey: {
      100: "#EDEBF0",
      200: "#CCC9D1",
      300: "#ABA7B2",
      400: "#918D99",
      50: "#F5F5F7",
      500: "#777380",
      600: "#5E5A66",
      700: "#45414C",
      800: "#2E2B33",
      900: "#171619",
    },
    info: {
      dark: "#005DE8",
      light: "#E0F1FA",
      main: "#50B0E5",
    },
    primary: {
      dark: "#290063",
      light: "#ECEBFA",
      lightest: "#F6F5FC",
      main: "#4500A5",
      medium: "#8876DB",
    },
    secondary: {
      dark: "#5A1F99",
      light: "#C085FF",
      lightest: "#FAF5FF",
      main: "#9633FF",
      medium: "#B4B0FF",
    },
    success: {
      dark: "#3B9464",
      light: "#DEF3E8",
      main: "#59C28A",
    },
    warning: {
      dark: "#B38000",
      light: "#FFF4E6",
      main: "#FFC233",
    },
    white: "#FFFFFF",
  },
} as const;

export const EnergyTexasTheme: DefaultTheme = {
  colors: {
    black: "#000000",
    disabled: {
      background: "#EDF0F2",
      border: "#EDF0F2",
      hover: "#77808A",
      text: "#77808A",
    },
    error: {
      dark: "#960000",
      light: "#FFE6E6",
      main: "#C80000",
    },
    grey: {
      100: "#E9EBF1",
      200: "#C8C8D6",
      300: "#A8A7BC",
      400: "#908EA7",
      50: "#F0F2F7",
      500: "#787591",
      600: "#5F5E77",
      700: "#47465C",
      800: "#302F43",
      900: "#191823",
    },
    info: {
      dark: "#0276aa",
      light: "#e1f5fe",
      main: "#00afef",
    },
    primary: {
      dark: "#0f2347",
      light: "#edf4f7",
      lightest: "#f5fafc",
      main: "#32a9ec",
      medium: "#bbe6f7",
    },
    secondary: {
      dark: "#102247",
      light: "#00afef",
      lightest: "#e6f7fd",
      main: "#0f2347",
      medium: "#0a6b9b",
    },
    success: {
      dark: "#3B9464",
      light: "#DEF3E8",
      main: "#59C28A",
    },
    warning: {
      dark: "#B38000",
      light: "#FFF4E6",
      main: "#FFC233",
    },
    white: "#FFFFFF",
  },
};
