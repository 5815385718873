import {
  CreateCustomerRequest,
  createCustomerRequestSchema,
} from "@common/api/apiRequests";
import { ChangePasswordResponseType } from "@common/api/customerResponseTypes";
import {
  notificationChannelsList,
  notificationMessagesList,
} from "@common/api/customerUrls";
import { urls } from "@common/api/urls";
import { AppBrands } from "@common/enums/appBrands.enum";
import { ajax } from "@common/services/ajax";
import {
  ChatAvailabilityResponseType,
  CustomerAvailableResponseType,
  CustomerSearchResponseType,
  GetSetPasswordStateTokenResponseType,
  NotificationChannelsResponseType,
  NotificationMessagesResponseType,
  OffersAvailabilityResponseType,
  RewardsResponseType,
  SetPasswordResponseType,
  TriggerEdiResponseType,
  UpdateSubscriptionStatusResponseType,
} from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { ContractExpirationNoticeType } from "@common/types/contractTypes";
import {
  AddressFormType,
  CustomerListItemType,
  CustomerType,
  CustomerUpdatePostDataType,
} from "@common/types/customerTypes";
import { EDIGeneratePostDataType } from "@common/types/ediTypes";
import {
  ChannelSubscriptionStatus,
  UpdateSubscriptionParams,
} from "@common/types/notificationTypes";
import { captureException, withScope } from "@sentry/react";

export const api = {
  chat: {
    availability: (): Promise<ChatAvailabilityResponseType> => {
      return ajax.get(urls.chat.availability());
    },
  },
  contracts: {
    expirationNotices: {
      list: (premiseId: IdType): Promise<ContractExpirationNoticeType[]> => {
        return ajax.get(urls.contracts.expirationNotices.list(premiseId));
      },
    },
  },
  customers: {
    available: (email: string): Promise<CustomerAvailableResponseType> => {
      return ajax.get(urls.customers.available(), { params: { email } });
    },

    create: <R = CustomerType>(
      customerData: CreateCustomerRequest
    ): Promise<R> => {
      try {
        createCustomerRequestSchema.validateSync(customerData);
      } catch (error: unknown) {
        withScope((scope) => {
          const errorData: {
            customerData: string;
            errorMessage: string;
            errorStack?: string;
          } = {
            customerData: JSON.stringify(customerData, null, 2),
            errorMessage: "Something went wrong",
            errorStack: undefined,
          };

          if (error instanceof Error) {
            errorData.errorMessage = error.message;
            errorData.errorStack = error.stack;
          }

          scope.setContext("Create customer request body malformed", errorData);
          captureException(error);
        });
      }

      return ajax.post<R, CreateCustomerRequest>(
        urls.customers.create(),
        customerData
      );
    },
    index: (): Promise<CustomerListItemType[]> => {
      return ajax.get(urls.customers.index());
    },
    me: <R = CustomerType>(): Promise<R> => {
      return ajax.get<R>(urls.customers.me());
    },
    notificationChannels: {
      list: (): Promise<NotificationChannelsResponseType> => {
        return ajax.get(notificationChannelsList());
      },
    },
    notificationMessages: {
      list: (): Promise<NotificationMessagesResponseType> => {
        return ajax.get(notificationMessagesList());
      },
    },
    password: {
      change: ({
        id,
        oldPassword,
        newPassword,
        confirmNewPassword,
      }: {
        confirmNewPassword: string;
        id: IdType;
        newPassword: string;
        oldPassword: string;
      }): Promise<ChangePasswordResponseType> => {
        return ajax.post(urls.customers.password.change(id), {
          confirmNewPassword,
          newPassword,
          oldPassword,
        });
      },
      getStateToken: ({
        activationToken,
      }: {
        activationToken: string;
      }): Promise<GetSetPasswordStateTokenResponseType> => {
        const payload = { activationToken };

        return ajax.post(urls.customers.password.getStateToken(), payload);
      },
      reset: ({
        recoveryToken,
        password,
        confirmPassword,
      }: {
        confirmPassword: string;
        password: string;
        recoveryToken: string;
      }): Promise<SetPasswordResponseType> => {
        const payload = {
          confirmPassword,
          password,
          recoveryToken,
        };

        return ajax.post(urls.customers.password.reset(), payload);
      },
      sendResetEmail: ({
        brand,
        email,
        referrer,
      }: {
        brand?: AppBrands;
        email: string;
        referrer?: string;
      }): Promise<void> => {
        return ajax.post(urls.customers.password.sendResetEmail(), {
          brand,
          email,
          referrer,
        });
      },
      set: <R = SetPasswordResponseType>({
        activationToken,
        password,
        confirmPassword,
        stateToken,
      }: {
        activationToken?: string;
        confirmPassword: string;
        password: string;
        stateToken?: string;
      }): Promise<R> => {
        const payload = {
          activationToken,
          confirmPassword,
          password,
          stateToken,
        };

        return ajax.post<R>(urls.customers.password.set(), payload);
      },
    },
    retrieve: (id: IdType, params?: unknown): Promise<CustomerType> => {
      return ajax.get(urls.customers.retrieve(id), { params });
    },
    rewards: (customerId: IdType): Promise<RewardsResponseType> => {
      return ajax.get(urls.customers.loyaltyProgram.retrieve(customerId));
    },
    search: (query: string): Promise<CustomerSearchResponseType> => {
      return ajax.get<CustomerSearchResponseType>(urls.customers.search(query));
    },
    tearDown: <R = void>(customerId: IdType): Promise<R> => {
      if (!import.meta.env.VITE_CONTRACT_API_ENV) {
        throw new Error("Customer cannot be deleted!");
      }
      return ajax.destroy<R>(urls.customers.tearDown(customerId));
    },
    unsubscribedChannelIds: (id: IdType): Promise<ChannelSubscriptionStatus> =>
      ajax.get<ChannelSubscriptionStatus>(
        urls.customers.unsubscribedChannelIds(id)
      ),
    update: (
      customerId: IdType,
      customerData: Partial<CustomerType> & Partial<AddressFormType>,
      premiseId?: IdType
    ): Promise<CustomerType> => {
      return ajax.patch<CustomerType, CustomerUpdatePostDataType>(
        urls.customers.update(customerId),
        { ...customerData, activePremiseId: premiseId }
      );
    },
    updateSubscriptionStatus: (
      id: IdType,
      subscriptionParams: UpdateSubscriptionParams
    ): Promise<UpdateSubscriptionStatusResponseType> =>
      ajax.patch<UpdateSubscriptionStatusResponseType>(
        urls.customers.updateSubscriptionStatus(id),
        subscriptionParams
      ),
  },
  ediProvider: {
    triggerEDIGeneration: (
      premiseId: IdType,
      ediIntent: string
    ): Promise<TriggerEdiResponseType> => {
      return ajax.post<TriggerEdiResponseType, EDIGeneratePostDataType>(
        urls.ediProvider.triggerEdiGeneration(),
        {
          ediIntent,
          premiseId,
        }
      );
    },
  },
  pricing: {
    offerSnapshots: {
      availability: (
        streetAddress: Partial<AddressFormType>
      ): Promise<OffersAvailabilityResponseType> => {
        return ajax.get(urls.pricing.offerSnapshots.availability(), {
          params: { ...streetAddress },
        });
      },
    },
  },
};
