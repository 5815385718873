import { useLocation, useParams } from "react-router-dom";

interface DeprecatedOktaResetPasswordPageRouteParams {
  token: string;
}

export const useResetPasswordPageParams = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const { token } = useParams<DeprecatedOktaResetPasswordPageRouteParams>();
  const referrer = query.get("referrer");

  return { referrer, token };
};
