export const DeprecatedOktaResetPasswordPageTranslations: Record<
  string,
  string
> = {
  tDeprecatedOktaResetPasswordPageErrorResettingPassword:
    "We are having issues changing your password. Please try again.",
  tDeprecatedOktaResetPasswordPageInvalidPassword:
    "Does not meet the requirements of the password policy.",
  tDeprecatedOktaResetPasswordPageNextCTA: "Next",
  tDeprecatedOktaResetPasswordPagePassword: "Password",
  tDeprecatedOktaResetPasswordPagePasswordMinimumRequirements:
    "Use 8 or more characters with a mix of letters, numbers and symbols.",
  tDeprecatedOktaResetPasswordPageTitle: "Create a new password",
};
