import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { Tabs, TabsProps } from "@mui/material";
import styled from "styled-components";

interface StyledRhRoundTabsProps extends TabsProps {
  $contained: boolean;
}

export const StyledRhRoundTabs = styled(Tabs)<StyledRhRoundTabsProps>`
  &.MuiTabs-root {
    height: 32px;

    & button.selected {
      color: ${({ $contained }) =>
        $contained
          ? CSSVariables.COLOR_WHITE
          : CSSVariables.COLOR_PRIMARY_MAIN};
      z-index: 100;
    }

    & button:hover:not(.selected) {
      background-color: ${({ $contained }) =>
        $contained ? CSSVariables.COLOR_PRIMARY_LIGHT : "transparent"};
      border-radius: 1000px;
    }

    @media (min-width: ${RhythmBreakpoints.SM}px) {
      width: "100%";
    }
  }

  & .MuiTabs-scroller {
    background-color: green;

    background-color: ${({ $contained }) =>
      $contained ? CSSVariables.COLOR_PRIMARY_LIGHTEST : "transparent"};
    border-radius: ${({ $contained }) => ($contained ? "1000px" : 0)};
    flex: ${({ $contained }) => ($contained ? "0 1 auto" : "1 1 100%")};
    height: 32px;
    margin: 0 auto;
    width: auto;
  }

  & .MuiTabs-indicator {
    background: none;
    bottom: 3px;
    height: auto;
    right: 3;
    top: 0;
    z-index: 0;

    &:after {
      background: ${({ $contained }) =>
        $contained
          ? CSSVariables.COLOR_PRIMARY_MAIN
          : CSSVariables.COLOR_GREY_50};
      border-radius: 18px;
      bottom: 1;
      content: "";
      display: block;
      height: 32px;
      left: ${({ $contained }) => ($contained ? 0 : 2)};
      position: absolute;
      right: 2;
      top: ${({ $contained }) => ($contained ? 0 : -1)};
      width: 100%;
    }
  }
`;
