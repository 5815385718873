import { passwordTokenParam } from "@common/services/resetPassword";
import {
  enrollBasePath,
  enrollOffersErrorPath,
  enrollOffersPath,
  signUpBasePath,
  signUpLeadGenPath,
  signUpResumePath,
} from "@enroll-utils/constants/routePaths";
import { LoginCallback } from "@okta/okta-react";
import { AccountFooterLoggedIn } from "@portal-account/components/AccountFooterLoggedIn/AccountFooterLoggedIn";
import { AccountFooterLoggedOut } from "@portal-account/components/AccountFooterLoggedOut/AccountFooterLoggedOut";
import { AccountGlobalLogic } from "@portal-account/components/AccountGlobalLogic/AccountGlobalLogic";
import { AccountLoader } from "@portal-account/components/AccountLoader/AccountLoader";
import { AccountModalsManager } from "@portal-account/components/AccountModalsManager/AccountModalsManager";
import { ActivationController } from "@portal-account/components/ActivationController/ActivationController";
import { CustomerProvider } from "@portal-account/components/CustomerProvider/CustomerProvider";
import { GlobalAnnouncement } from "@portal-account/components/GlobalAnnouncement/GlobalAnnouncement";
import { LoggedOutHeader } from "@portal-account/components/LoggedOutHeader/LoggedOutHeader";
import { MyAccountHeader } from "@portal-account/components/MyAccountHeader/MyAccountHeader";
import { PortalFeatureFlagClientProvider } from "@portal-account/components/PortalFeatureFlagClientProvider/PortalFeatureFlagClientProvider";
import { PremiseIdChooser } from "@portal-account/components/PremiseIdChooser/PremiseIdChooser";
import { PremiseProvider } from "@portal-account/components/PremiseProvider/PremiseProvider";
import { ProductAddOnsOptInRoutes } from "@portal-account/components/ProductAddOnsOptIn/ProductAddOnsOptIn.route";
import { AccountCreationPendingPage } from "@portal-account/pages/AccountCreationPendingPage/AccountCreationPendingPage";
import { BillBreakdownPage } from "@portal-account/pages/BillBreakdownPage/BillBreakdownPage";
import { CreatePasswordPage } from "@portal-account/pages/CreatePasswordPage/CreatePasswordPage";
import { CustomerHomePage } from "@portal-account/pages/CustomerHomePage/CustomerHomePage";
import { DeprecatedOktaResetPasswordPage } from "@portal-account/pages/DeprecatedOktaResetPasswordPage/DeprecatedOktaResetPasswordPage";
import { DevicesEnrollChooseBrandPage } from "@portal-account/pages/DevicesEnrollChooseBrandPage/DevicesEnrollChooseBrandPage";
import { DevicesEnrollSelectDevicesPage } from "@portal-account/pages/DevicesEnrollSelectDevicesPage/DevicesEnrollSelectDevicesPage";
import { DevicesEnrollSummaryPage } from "@portal-account/pages/DevicesEnrollSummaryPage/DevicesEnrollSummaryPage";
import { DevicesEnrollTermsPage } from "@portal-account/pages/DevicesEnrollTermsPage/DevicesEnrollTermsPage";
import { DevicesPage } from "@portal-account/pages/DevicesPage/DevicesPage";
import { ExpiredPasswordTokenPage } from "@portal-account/pages/ExpiredPasswordTokenPage/ExpiredPasswordTokenPage";
import { ForgotPasswordPage } from "@portal-account/pages/ForgotPasswordPage/ForgotPasswordPage";
import { MyAccountPage } from "@portal-account/pages/MyAccountPage/MyAccountPage";
import { NotificationsPage } from "@portal-account/pages/NotificationsPage/NotificationsPage";
import { PayBillPage } from "@portal-account/pages/PayBillPage/PayBillPage";
import { PayBillSuccessPage } from "@portal-account/pages/PayBillSuccessPage/PayBillSuccessPage";
import { PaymentExtensionPage } from "@portal-account/pages/PaymentExtensionPage/PaymentExtensionPage";
import { RenewalsPage } from "@portal-account/pages/RenewalsPage/RenewalsPage";
import { RenewalsSummaryPage } from "@portal-account/pages/RenewalsSummaryPage/RenewalsSummaryPage";
import { RewardsPage } from "@portal-account/pages/RewardsPage/RewardsPage";
import { SignInPage } from "@portal-account/pages/SignInPage/SignInPage";
import { SignOutPage } from "@portal-account/pages/SignOutPage/SignOutPage";
import { CustomerHomeOutlet } from "@portal-account/routes/CustomerHomeOutlet";
import { DeviceManagementOutlet } from "@portal-account/routes/DeviceManagementOutlet";
import {
  accountCreationPendingPath,
  accountRenewalsPath,
  activatePath,
  billBreakdownPath,
  devicesEnrollChooseYourBrandDeviceStep,
  devicesEnrollSelectDevicesStep,
  devicesEnrollStepPath,
  devicesEnrollSummaryStep,
  devicesEnrollTermsStep,
  expiredPasswordPath,
  forgotPasswordPath,
  loginCallbackPath,
  myAccountPath,
  notificationsPath,
  payBillPath,
  payBillSuccessPath,
  paymentExtensionPath,
  productAddOnsOptInPath,
  resetPasswordPath,
  rewardsPath,
} from "@portal-account/routes/routePaths";
import { accountStore } from "@portal-account/store/accountStore";
import { EnrollFeatureFlagClientProvider } from "@portal-enroll/components/EnrollFeatureFlagClientProvider/EnrollFeatureFlagClientProvider";
import { EnrollGlobalLogic } from "@portal-enroll/components/EnrollGlobalLogic/EnrollGlobalLogic";
import { EnrollHeader } from "@portal-enroll/components/EnrollHeader/EnrollHeader";
import { EnrollModalsManager } from "@portal-enroll/components/EnrollModalsManager/EnrollModalsManager";
import { EnrollReferralCodeAnnouncement } from "@portal-enroll/components/EnrollReferralCodeAnnouncement/EnrollReferralCodeAnnouncement";
import { ProspectProvider } from "@portal-enroll/components/ProspectProvider/ProspectProvider";
import { SignUpOutlet } from "@portal-enroll/components/SignOutOutlet/SignUpOutlet";
import { SignUpResume } from "@portal-enroll/components/SignUpResume/SignUpResume";
import { TrackFullStoryUserId } from "@portal-enroll/components/TrackFullStoryUserId/TrackFullStoryUserId";
import { EnrollErrorPage } from "@portal-enroll/pages/EnrollErrorPage/EnrollErrorPage";
import { EnrollIndexPage } from "@portal-enroll/pages/EnrollIndexPage/EnrollIndexPage";
import { EnrollOffersPage } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPage";
import { SignUpRoutes } from "@portal-enroll/routes/SignUpRoutes";
import { signUpStore } from "@portal-enroll/store/signUpStore";
import { GuestPayAccountLookup } from "@portal-guest/components/GuestPayAccountLookup/GuestPayAccountLookup";
import { GuestPayAccountSummary } from "@portal-guest/components/GuestPayAccountSummary/GuestPayAccountSummary";
import { GuestAndQuickPayConfirmationPage } from "@portal-guest/pages/GuestAndQuickPayConfirmationPage/GuestAndQuickPayConfirmationPage";
import { OneClickRenewalPage } from "@portal-guest/pages/OneClickRenewalPage/OneClickRenewalPage";
import { OneClickRenewalSummaryPage } from "@portal-guest/pages/OneClickRenewalSummaryPage/OneClickRenewalSummaryPage";
import { QuickPayPage } from "@portal-guest/pages/QuickPayPage/QuickPayPage";
import { GuestRouteProvidersWithOutlet } from "@portal-guest/routes/GuestRouteProvidersWithOutlet";
import {
  guestPayAccountLookUpPath,
  guestPayAccountSummaryPath,
  guestPayBasePath,
  guestPayConfirmationPath,
  oneClickRenewalsPath,
  quickPayConfirmationPath,
  quickPayPath,
} from "@portal-guest/routes/routePaths";
import { AwsLoggedOutConnectHelmet } from "@portal-shared/components/AwsLoggedOutConnectHelmet/AwsLoggedOutConnectHelmet";
import { IntlError } from "@portal-shared/components/IntlError/IntlError";
import { LoggedOutFooter } from "@portal-shared/components/LoggedOutFooter/LoggedOutFooter";
import { LoggedOutPageLayout } from "@portal-shared/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { PortalContent } from "@portal-shared/components/PortalContent/PortalContent.styled";
import { PortalSharedMainContainer } from "@portal-shared/components/PortalSharedMainContainer/PortalSharedMainContainer";
import { SentryRoutes } from "@portal-shared/components/SentryRoutes/SentryRoutes";
import { SessionCheck } from "@portal-shared/components/SessionCheck/SessionCheck";
import { SessionManager } from "@portal-shared/components/SessionManager/SessionManager";
import { SharedModalsManager } from "@portal-shared/components/SharedModalsManager/SharedModalsManager";
import { Error404Page } from "@portal-shared/pages/Error404Page/Error404Page";
import {
  createPasswordPath,
  customerHomePath,
  intlErrorPath,
  rootPath,
  signInPath,
  signOutPath,
} from "@portal-shared/routes/routePaths";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Provider as StoreProvider } from "react-redux";
import { Navigate, Outlet, Route } from "react-router-dom";

export const PortalRoutes = () => {
  return (
    <SentryRoutes>
      {/* Account routes */}
      <Route
        element={
          <SessionManager>
            <StoreProvider store={accountStore}>
              <PortalFeatureFlagClientProvider>
                <SharedModalsManager />
                <PortalSharedMainContainer>
                  <Outlet />
                </PortalSharedMainContainer>
              </PortalFeatureFlagClientProvider>
            </StoreProvider>
          </SessionManager>
        }
      >
        <Route
          element={
            <SessionCheck secured={false}>
              <LoggedOutHeader />
              <AwsLoggedOutConnectHelmet />
              <PortalContent>
                <Outlet />
              </PortalContent>
              <AccountFooterLoggedOut />
            </SessionCheck>
          }
        >
          <Route path={createPasswordPath()} element={<CreatePasswordPage />} />
          <Route
            path={expiredPasswordPath()}
            element={<ExpiredPasswordTokenPage />}
          />
          <Route
            path={activatePath(":activationToken")}
            element={<ActivationController />}
          />
          <Route path={signInPath()} element={<Navigate to={rootPath()} />} />
          <Route path={forgotPasswordPath()} element={<ForgotPasswordPage />} />
          <Route
            path={resetPasswordPath(passwordTokenParam)}
            element={<DeprecatedOktaResetPasswordPage />}
          />
          <Route index element={<SignInPage />} />
        </Route>

        <Route
          element={
            <SessionCheck secured>
              <Helmet>
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="/assets/styles/awsConnectWrapper.css"
                />
                <script
                  type="text/javascript"
                  src="assets/js/customerAwsConnectChat.js"
                />
              </Helmet>
              <AccountLoader>
                <CustomerProvider>
                  <PremiseIdChooser>
                    <PremiseProvider>
                      <AccountGlobalLogic />
                      <GlobalAnnouncement />
                      <AccountModalsManager />
                      <MyAccountHeader />
                      <Outlet />
                      <AccountFooterLoggedIn />
                    </PremiseProvider>
                  </PremiseIdChooser>
                </CustomerProvider>
              </AccountLoader>
            </SessionCheck>
          }
        >
          <Route path={customerHomePath()} element={<CustomerHomeOutlet />}>
            <Route index element={<CustomerHomePage />} />
          </Route>
          <Route
            path={`${billBreakdownPath(":invoiceId")}/*`}
            element={<BillBreakdownPage />}
          />

          <Route path={payBillSuccessPath()} element={<PayBillSuccessPage />} />
          <Route path={payBillPath()} element={<PayBillPage />} />
          <Route
            path={paymentExtensionPath()}
            element={<PaymentExtensionPage />}
          />
          <Route path={rewardsPath()} element={<RewardsPage />} />
          <Route path={myAccountPath()} element={<MyAccountPage />} />
          <Route path={myAccountPath()} element={<MyAccountPage />} />
          <Route
            path={`${productAddOnsOptInPath()}/*`}
            element={<ProductAddOnsOptInRoutes />}
          />
          <Route path={notificationsPath()} element={<NotificationsPage />} />
          <Route path={`${accountRenewalsPath()}/*`}>
            <Route index element={<RenewalsPage />} />
            <Route path="summary" element={<RenewalsSummaryPage />} />
          </Route>
          <Route path="devices" element={<DeviceManagementOutlet />}>
            <Route index element={<DevicesPage />} />
            <Route path="enroll" element={<Outlet />}>
              <Route
                index
                element={
                  <Navigate
                    to={devicesEnrollStepPath("choose-your-device-brand")}
                  />
                }
              />
              <Route
                path={devicesEnrollChooseYourBrandDeviceStep()}
                element={<DevicesEnrollChooseBrandPage />}
              />
              <Route
                path={devicesEnrollTermsStep()}
                element={<DevicesEnrollTermsPage />}
              />
              <Route
                path={devicesEnrollSelectDevicesStep()}
                element={<DevicesEnrollSelectDevicesPage />}
              />
              <Route
                path={devicesEnrollSummaryStep()}
                element={<DevicesEnrollSummaryPage />}
              />
            </Route>
          </Route>

          <Route
            path={accountCreationPendingPath()}
            element={<AccountCreationPendingPage />}
          />
        </Route>
        <Route path={signOutPath()} element={<SignOutPage />} />
        <Route path={loginCallbackPath()} element={<LoginCallback />} />
      </Route>
      {/* Enroll routes */}
      <Route
        element={
          <SessionManager>
            <StoreProvider store={signUpStore}>
              <EnrollFeatureFlagClientProvider>
                <TrackFullStoryUserId />
                <EnrollGlobalLogic />
                <EnrollModalsManager />
                <SharedModalsManager />
                <PortalSharedMainContainer>
                  <EnrollHeader />
                  <PortalContent>
                    <SessionCheck secured={false}>
                      <Outlet />
                    </SessionCheck>
                  </PortalContent>
                  <LoggedOutFooter />
                </PortalSharedMainContainer>
              </EnrollFeatureFlagClientProvider>
            </StoreProvider>
          </SessionManager>
        }
      >
        <Route
          path={`${signUpLeadGenPath(":uuid")}/*`}
          element={<SignUpResume />}
        />
        <Route
          path={`${signUpResumePath(":uuid")}/*`}
          element={<SignUpResume />}
        />
        <Route
          element={
            <ProspectProvider>
              <Outlet />
            </ProspectProvider>
          }
        >
          <Route element={<SignUpOutlet />}>
            <Route path={`${signUpBasePath()}/*`} element={<SignUpRoutes />} />
          </Route>
          <Route
            path={enrollBasePath()}
            element={
              <>
                <EnrollReferralCodeAnnouncement />
                <Outlet />
              </>
            }
          >
            <Route index element={<EnrollIndexPage />} />

            <Route path={enrollOffersPath()} element={<EnrollOffersPage />} />
            <Route
              path={enrollOffersErrorPath()}
              element={<EnrollErrorPage />}
            />
          </Route>
        </Route>
      </Route>
      {/* Guest routes */}
      <Route element={<GuestRouteProvidersWithOutlet />}>
        <Route path={quickPayPath(":token")} element={<QuickPayPage />} />
        <Route
          path={quickPayConfirmationPath()}
          element={
            <LoggedOutPageLayout>
              <GuestAndQuickPayConfirmationPage />
            </LoggedOutPageLayout>
          }
        />
        <Route
          path={guestPayBasePath()}
          element={
            <LoggedOutPageLayout>
              <Outlet />
            </LoggedOutPageLayout>
          }
        >
          <Route
            path={guestPayAccountLookUpPath()}
            element={<GuestPayAccountLookup />}
          />
          <Route
            path={guestPayAccountSummaryPath()}
            element={<GuestPayAccountSummary />}
          />
          <Route
            path={guestPayConfirmationPath()}
            element={<GuestAndQuickPayConfirmationPage />}
          />
        </Route>
        <Route path={`${oneClickRenewalsPath(":renewalToken")}/*`}>
          <Route index element={<OneClickRenewalPage />} />
          <Route path="summary" element={<OneClickRenewalSummaryPage />} />
        </Route>
      </Route>
      <Route path={intlErrorPath()} element={<IntlError />} />
      <Route path="*" element={<Error404Page />} />
    </SentryRoutes>
  );
};
