import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { LOGGED_OUT_FIELD_SPACING } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.constants";
import styled from "styled-components";

export const StyledDeprecatedOktaResetPasswordPageCaption = styled(
  RhTypography
)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_400};
    display: block;
    margin-top: ${rhSpacingPx(LOGGED_OUT_FIELD_SPACING)};
  }
`;
