import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { ResetPasswordForm } from "@portal-account/components/ResetPasswordForm/ResetPasswordForm";
import { DeprecatedOktaResetPasswordPageTranslations } from "@portal-account/pages/DeprecatedOktaResetPasswordPage/DeprecatedOktaResetPasswordPage.en.i18n";
import { ResetPasswordViaOktaForm } from "@portal-account/pages/DeprecatedOktaResetPasswordPage/ResetPasswordViaOktaForm";
import { useResetPasswordPageParams } from "@portal-account/pages/DeprecatedOktaResetPasswordPage/useResetPasswordPageParams";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal-shared/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

/**
 * This page is deprecated in favor of using the CreatePasswordPage for internal auth
 * Bessy Medina confirmed this is fine
 *
 * The reset password link sent to the email will direct user to the
 * create password page if the customer is using internal auth.
 *
 * Otherwise, if user is still in Okta, it will continue to arrive here
 */
export const DeprecatedOktaResetPasswordPage = () => {
  const { token: recoveryToken } = useResetPasswordPageParams();

  const { translate } = useTranslations();

  const { tDeprecatedOktaResetPasswordPageTitle } = translate(
    DeprecatedOktaResetPasswordPageTranslations
  );

  const { featureFlagClient } = useFeatureFlagClient();

  const { portalAccountOktaBackendProxy } = featureFlagClient.getFlags([
    ["portalAccountOktaBackendProxy", false],
  ]);

  return (
    <LoggedOutPageLayout>
      <LoggedOutPageHeader headerText={tDeprecatedOktaResetPasswordPageTitle} />
      {portalAccountOktaBackendProxy.value ? (
        <ResetPasswordForm recoveryToken={recoveryToken} />
      ) : (
        <ResetPasswordViaOktaForm recoveryToken={recoveryToken} />
      )}
    </LoggedOutPageLayout>
  );
};
