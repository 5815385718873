import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { createPasswordFormTranslations } from "@portal-account/components/CreatePasswordForm/CreatePasswordForm.en.i18n";
import {
  CreatePasswordFormCircularProgress,
  CreatePasswordFormContainer,
  CreatePasswordFormCtaButton,
  CreatePasswordFormPasswordRequirements,
} from "@portal-account/components/CreatePasswordForm/CreatePasswordForm.styled";
import { StyledLoggedOutForm } from "@portal-shared/components/LoggedOutForm/LoggedOutForm.styled";
import { PortalPasswordInput } from "@portal-shared/components/PortalPasswordInput/PortalPasswordInput";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useCreatePasswordMutation } from "@portal-shared/mutations/useCreatePasswordMutation";
import { customerHomePath } from "@portal-shared/routes/routePaths";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

interface CreatePasswordFormValues {
  password: string;
}

interface CreatePasswordFormProps {
  activationToken: string;
}

export const CreatePasswordForm = (props: CreatePasswordFormProps) => {
  const { activationToken } = props;
  const flash = useRhFlash();
  const [stateToken, setStateToken] = useState<string | undefined>();
  const navigate = useNavigate();

  const createPasswordMutation = useCreatePasswordMutation();
  const formMethods = useForm<CreatePasswordFormValues>();

  const {
    handleSubmit,
    formState: { isSubmitted, errors },
    register,
  } = formMethods;

  const { translate } = useTranslations();

  const {
    tCreatePasswordFormCreatePassword,
    tCreatePasswordFormPasswordRequired,
    tCreatePasswordFormCreatingPassword,
    tCreatePasswordFormErrorCreatingPassword,
    tCreatePasswordFormPassword,
    tCreatePasswordFormPasswordRequirements,
  } = translate(createPasswordFormTranslations);

  const passwordInputFormProps = register("password", {
    required: tCreatePasswordFormPasswordRequired,
  });

  const onSubmit = handleSubmit(({ password }: CreatePasswordFormValues) => {
    if (errors.password) {
      return;
    }

    createPasswordMutation.mutate(
      { activationToken, password, stateToken },
      {
        onError: (error) => {
          const newStateToken = error.data?.errors?.[0]?.detail;

          if (
            newStateToken &&
            error.data.errorCode ===
              "authentication_portal.error.unacceptable_password"
          ) {
            setStateToken(newStateToken);
          }

          flash.error(tCreatePasswordFormErrorCreatingPassword);
        },
        onSuccess: () => {
          flash.close();

          navigate(customerHomePath());
        },
      }
    );
  });

  const ctaButtonDisabled = Boolean(
    isSubmitted && (errors.password || createPasswordMutation.isPending)
  );

  const passwordErrorMessage = errors.password?.message;

  return (
    <CreatePasswordFormContainer>
      <FormProvider {...formMethods}>
        <StyledLoggedOutForm onSubmit={onSubmit}>
          <PortalPasswordInput
            label={tCreatePasswordFormPassword}
            errorMessage={passwordErrorMessage}
            inputProps={{
              id: "password",
              ...passwordInputFormProps,
            }}
          />

          <CreatePasswordFormPasswordRequirements variant="caption">
            {tCreatePasswordFormPasswordRequirements}
          </CreatePasswordFormPasswordRequirements>
          <CreatePasswordFormCtaButton
            data-tracking-click={{
              event: "Customer attempting create password",
            }}
            type="submit"
            disabled={ctaButtonDisabled}
            color="primary"
          >
            {createPasswordMutation.isPending ? (
              <>
                <CreatePasswordFormCircularProgress color="inherit" size={14} />
                <RhTypography color="inherit" variant="inherit">
                  {tCreatePasswordFormCreatingPassword}
                </RhTypography>
              </>
            ) : (
              tCreatePasswordFormCreatePassword
            )}
          </CreatePasswordFormCtaButton>
        </StyledLoggedOutForm>
      </FormProvider>
    </CreatePasswordFormContainer>
  );
};
