import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { SessionCheckAuthn } from "@portal-shared/components/SessionCheckAuthn/SessionCheckAuthn";
import { SessionCheckOkta } from "@portal-shared/components/SessionCheckOkta/SessionCheckOkta";
import React, { PropsWithChildren, useEffect, useState } from "react";

interface SessionCheckProps {
  secured: boolean;
}

export const SessionCheck = (props: PropsWithChildren<SessionCheckProps>) => {
  const { children, secured } = props;
  const [isLoading, setIsLoading] = useState(true);
  const { featureFlagClient } = useFeatureFlagClient();
  const { portalAccountOktaBackendProxy } = featureFlagClient.getFlags([
    ["portalAccountOktaBackendProxy", false],
  ]);

  const isFetchingFlags = featureFlagClient.isFetchingFlags();

  useEffect(() => {
    if (!isFetchingFlags) {
      setIsLoading(false);
    }
  }, [isFetchingFlags]);

  if (isLoading) {
    return;
  }

  if (portalAccountOktaBackendProxy.value) {
    return <SessionCheckAuthn secured={secured}>{children}</SessionCheckAuthn>;
  }

  return <SessionCheckOkta secured={secured}>{children}</SessionCheckOkta>;
};
