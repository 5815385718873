import { api } from "@common/api/api";
import { AuthClient } from "@common/services/AuthClient.service";
import { rootPath } from "@portal-shared/routes/routePaths";
import { OKTA_CONFIG } from "@portal-shared/settings/config";

export class PortalAuthClient extends AuthClient {
  public override signIn(email: string, password: string) {
    return super.signIn(email, password);
  }

  public resetPassword(payload: { password: string; recoveryToken: string }) {
    return api.customers.password
      .reset({
        ...payload,
        confirmPassword: payload.password,
      })
      .then(({ sessionToken }) => {
        this.redirectToRedirectUri(sessionToken);
      });
  }

  public createPassword(payload: {
    activationToken?: string;
    password: string;
    stateToken?: string;
  }) {
    return api.customers.password
      .set({
        ...payload,
        confirmPassword: payload.password,
      })
      .then(({ sessionToken }) => {
        this.redirectToRedirectUri(sessionToken);
      });
  }

  public signOut() {
    return this.authClient.signOut({
      postLogoutRedirectUri: `${window.location.origin}${rootPath()}`,
    });
  }
}

export const portalAuthClient = new PortalAuthClient(OKTA_CONFIG);
