import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { AppBrands } from "@common/enums/appBrands.enum";
import { getAppBrand } from "@common/services/getEnvApplicationSettings.service";
import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { signUpStepPath } from "@enroll-utils/constants/routePaths";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { ReactComponent as ComparePowerLogoIcon } from "@portal-enroll/images/ComparePowerLogo.svg";
import { DepositForm } from "@portal-enroll/pages/SignUpDepositRequiredPage/DepositForm";
import { DepositSelectionForm } from "@portal-enroll/pages/SignUpDepositRequiredPage/DepositSelectionForm";
import { signUpDepositRequiredPageTranslations } from "@portal-enroll/pages/SignUpDepositRequiredPage/SignUpDepositRequiredPage.en.i18n";
import {
  StyledComparePowerButton,
  StyledComparePowerContainer,
  StyledComparePowerSection,
  StyledDepositRequiredPageLine,
  StyledDepositRequiredPageOrRow,
} from "@portal-enroll/pages/SignUpDepositRequiredPage/SignUpDepositRequiredPage.styled";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { TrackingView } from "@portal-shared/components/TrackingView/TrackingView";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const allowedMediums = [
  "organic",
  "email",
  "cpc",
  "paid-social",
  "sms",
  "Video",
  "my-account",
  "Display",
  "ppc",
  "referral",
  "FFGG_Display",
  "tatari-streaming",
  "paid",
  "raf",
  "email-share",
];

export const SignUpDepositRequiredPage = () => {
  const appBrand = getAppBrand();

  const { creditEvaluation, depositAmount, depositAlternativeAmount } =
    useSelector(selectSignUpState);
  const { acquisitionMedium, uuid, zipCode } = useProspectFromContext();

  const { featureFlagClient } = useFeatureFlagClient();
  const navigate = useNavigate();

  const { translate } = useTranslations();

  const {
    tSignUpDepositRequiredPageDontWantToPay,
    tSignUpDepositRequiredPageShopForPlans,
    tSignUpDepositRequiredPageOr,
  } = translate(signUpDepositRequiredPageTranslations);

  const { portalDepositAlternativeProgram, portalComparePowerSection } =
    featureFlagClient.getFlags([
      ["portalDepositAlternativeProgram", false],
      ["portalComparePowerSection", false],
    ]);

  if (creditEvaluation === CreditScoreOutcome.manualReview) {
    navigate(signUpStepPath("call-us"));
  }

  const showDepositSelectionForm =
    depositAlternativeAmount &&
    depositAlternativeAmount !== "0" &&
    portalDepositAlternativeProgram.value;

  const showComparePower =
    portalComparePowerSection.value &&
    appBrand === AppBrands.RHYTHM &&
    (!acquisitionMedium ||
      allowedMediums
        .map((medium) => medium.toLowerCase())
        .includes(acquisitionMedium.toLowerCase()));

  const comparePowerUrl = brandInformation.urlsComparePower({
    trackingId: uuid,
    zipCode: zipCode ?? "",
  });

  return (
    <SignUpPageLayout>
      {showDepositSelectionForm ? (
        <DepositSelectionForm
          depositAmount={depositAmount}
          depositAlternativeAmount={depositAlternativeAmount}
        />
      ) : (
        <DepositForm depositAmount={depositAmount} />
      )}
      {showComparePower ? (
        <StyledComparePowerSection>
          <TrackingView data={{ event: "Compare Power section being shown" }} />
          <StyledDepositRequiredPageOrRow>
            <StyledDepositRequiredPageLine />
            <RhTypography variant="subtitle2">
              {tSignUpDepositRequiredPageOr}
            </RhTypography>
            <StyledDepositRequiredPageLine />
          </StyledDepositRequiredPageOrRow>
          <StyledComparePowerContainer>
            <RhTypography variant="h4">
              {tSignUpDepositRequiredPageDontWantToPay}
            </RhTypography>
            <RhTypography>
              {tSignUpDepositRequiredPageShopForPlans}
            </RhTypography>
            <StyledComparePowerButton
              fullWidth={false}
              data-tracking-click={{
                event: "Customer clicks Compare Power link",
              }}
              href={comparePowerUrl}
              target="__blank"
            >
              <ComparePowerLogoIcon />
            </StyledComparePowerButton>
          </StyledComparePowerContainer>
        </StyledComparePowerSection>
      ) : null}
    </SignUpPageLayout>
  );
};
