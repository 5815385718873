import { EnrollmentStatus } from "@common/enums/customer.enum";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhRouterLink } from "@design-system/components/RhRouterLink/RhRouterLink";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { translations } from "@portal-account/pages/PayBillSuccessPage/PayBillSuccessPage.en.i18n";
import {
  StyledGoHomeCTAWrapper,
  StyledPayBillSuccessImageTitle,
  StyledPayBillSuccessPage,
  StyledPayBillSuccessPageInner,
  StyledReconnectionMessaging,
  StyledThankYou,
  StyledYourRemainingBalance,
} from "@portal-account/pages/PayBillSuccessPage/PayBillSuccessPage.styled";
import { usePremiseAccountSummaryQuery } from "@portal-account/queries/usePremiseAccountSummaryQuery";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { rootPath } from "@portal-shared/routes/routePaths";
import React from "react";

export const PayBillSuccessPage = () => {
  const { translate, translateJsx } = useTranslations();
  const { portalCheckLoungeChair } = useBrandImageUrls();
  const { premise } = usePremiseFromContext();

  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    premiseId: premise.id,
  });

  if (accountSummaryQuery.isPending) {
    return <RhCircularProgress />;
  }
  if (accountSummaryQuery.isError) {
    return null;
  }

  const balance =
    Number(accountSummaryQuery.data && accountSummaryQuery.data.totalBalance) ||
    0;

  const {
    tPayBillSuccessPageGoHomeCTA,
    tPayBillSuccessPagePayBillSuccessImageTitle,
    tPayBillSuccessPageReconnectionNoContactNeeded,
    tPayBillSuccessPageReconnectionRequestSent,
    tPayBillSuccessPageReconnectionUpdates,
    tPayBillSuccessPageThankYou,
  } = translate(translations);

  const { tPayBillSuccessPageYourRemainingBalance } = translateJsx({
    tPayBillSuccessPageYourRemainingBalance: {
      balance: formatCurrency(balance),
    },
  });

  const showReconnection =
    premise.enrollmentStatus === EnrollmentStatus.DISCONNECTED && balance <= 0;

  return (
    <PortalPageLayout>
      <StyledPayBillSuccessPage>
        <StyledPayBillSuccessPageInner>
          <StyledPayBillSuccessImageTitle>
            <img
              src={portalCheckLoungeChair}
              alt={tPayBillSuccessPagePayBillSuccessImageTitle}
            />
          </StyledPayBillSuccessImageTitle>
          <StyledThankYou>
            <RhTypography variant="h2">
              {tPayBillSuccessPageThankYou}
            </RhTypography>
          </StyledThankYou>
          <StyledYourRemainingBalance>
            <RhTypography variant="subtitle2">
              {tPayBillSuccessPageYourRemainingBalance}
            </RhTypography>
          </StyledYourRemainingBalance>
          {showReconnection ? (
            <StyledReconnectionMessaging>
              <RhTypography variant="subtitle2" color="textSecondary">
                {tPayBillSuccessPageReconnectionRequestSent}
              </RhTypography>
              <RhTypography variant="subtitle2" color="textSecondary">
                {tPayBillSuccessPageReconnectionUpdates}
              </RhTypography>
              <RhTypography
                variant="subtitle2"
                color="textSecondary"
                fontWeight={FontWeight.Semibold}
              >
                {tPayBillSuccessPageReconnectionNoContactNeeded}
              </RhTypography>
            </StyledReconnectionMessaging>
          ) : null}
          <StyledGoHomeCTAWrapper>
            <RhRouterLink to={rootPath()} title={tPayBillSuccessPageGoHomeCTA}>
              <RhButton
                data-tracking-click={{
                  event:
                    "Customer going back to home after successfully paying",
                }}
                color="primary"
                fullWidth
              >
                {tPayBillSuccessPageGoHomeCTA}
              </RhButton>
            </RhRouterLink>
          </StyledGoHomeCTAWrapper>
        </StyledPayBillSuccessPageInner>
      </StyledPayBillSuccessPage>
    </PortalPageLayout>
  );
};
